import React from 'react';
import Pdf from './pages/Pdf'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Errorpage from './pages/Errorpage';
import './App.css';
import Home from './Home';


const App = () => (
  
  <Router>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/OtiumRacing" element={<Pdf/>} />
      <Route path="*" element={<Errorpage/>} />
    </Routes>
  </Router>
);

export default App;