import React from 'react';
import { Footer, Progress, WhatFEATUR, Header } from './containers';
import { CTA, Navbar } from './components';



const Home = () => (
  
    <div className="Home">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        {/* <Brand /> */}
        <WhatFEATUR />
        <Progress />
        <CTA />       
        <Footer />
    </div>

    
);

export default Home;