import React from 'react';
import './feature.css';

const Feature = ({ title, subtitleW, subtitle, text, subtext }) => (
  <div className="featur__progress-container__feature">
    <div className="featur__progress-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <h1 className="featur__progress-container__feature-subtitle">
      {subtitle}
    </h1>
    <h1 className="featur__progress-container__feature-subtitleW">
      {subtitleW}
    </h1>
    <div className="featur__progress-container_feature-text">
      <p>{text}</p>
    </div>
    <div className="featur__progress-container_feature-subtext">
      <p>{subtext}</p>
    </div>
  </div>
);

export default Feature;