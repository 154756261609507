import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="featur__cta" id="OTIUM">
    <div className="featur__cta-content">
      <h3>OTIUM Marketplace</h3>
      <p>Currently under construction. Join our Discord to be the first to know when it opens.</p>
    </div>
    <div className="featur__cta-btn">
      <button type="button">
      <a href= "https://www.discord.gg/55S32adaKN"> Join Discord </a>
      </button>
    </div>
  </div>
);

export default CTA;