import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatFEATUR.css';

const WhatFEATUR = () => (
  <div className="featur__whatfeatur section__margin" id="wfeatur">
    <div className="featur__whatfeatur-feature">
      <Feature title="What is Featur" 
      text="Featur is a digital development company that works with strategic partners
      and leading edge technology to bridge the gap between the real world and the coming digital age. " />
    </div>

    <div className="featur__whatfeatur-heading">
      <h1 className="gradient__text">Our Projects:</h1>
    </div>

    <div className="featur__whatfeatur-container">
      <Feature subtitleW="Otium Racing" 
      text="Otium Racing will be our first project. A racing game made for mobile, 
            that will allow for the community to make passive income with the addition of NFTs" />
      <Feature subtitleW="Otium Marketplace" 
      text="Otium Marketplace is set to be an additional piece to the Otium Racing game. 
            Allowing for the evolution of the game to be in the hands of the community." />
      <Feature subtitleW="Community" 
      text="A social media app for community sports and various other activities that take place in a city. 
            Creating a closer link between the general population."  />
    </div>
    
  </div>
);

export default WhatFEATUR;