import React from 'react'

const Errorpage = () => {
    return (
        <div>
            Error: Page not found
        </div>
    )
}

export default Errorpage
