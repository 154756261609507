import React from 'react';
import Feature from '../../components/feature/Feature';
import './progress.css';

const progressData = [
  {
    title: 'Otium Racing Whitepaper',
    text: ' The Otium Racing whitepaper will share our complete plan for Otium Racing including a roadmap on how we plan on making it a reality.',
    subtext: <nav>
            <p><a href="/OtiumRacing.pdf">View Here</a></p>
            </nav>,
  },
  {
    title: 'Marketing',
    text: " We have started our first marketing campaign with a focus on growing our social following and consistently sharing information on our various social platforms. ",
  },
  {
    title: 'Otium/Otium Racing',
    text: ' We are working on the various NFTs that will be assosiated with the game as well as bringing together the community that will be part of the creation and expansion of the game.',
  },
 
];

const Progress = () => (
  <div className="featur__progress section__padding" id="progress">
    <div className="featur__progress-heading">
      <h1 className="gradient__text">Progress</h1>
      <p> Where we currently are on our journey </p>
    </div>
    <div className="featur__progress-container">
      {progressData.map((item, index) => (
        <Feature subtitle={item.title} text={item.text} subtext={item.subtext} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Progress;