import React from 'react';
import featurLogo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="featur__footer section__padding">

    <div className="featur__footer-links">
      <div className="featur__footer-links_logo">
        <img src={featurLogo} alt="featur_logo" />
        <p>
          {/* Crechterwoord K12 182 DK Alknjkcb, <br />  */}
        All Rights Reserved</p>
      </div>
      <div className="featur__footer-links_div">
        <h4>Links</h4>
        <p><a href= "https://www.linkedin.com/company/featur-dev/"> LinkedIn </a></p>
        <p><a href= "https://www.instagram.com/featur_dev/"> Instagram </a></p>
        <p><a href= "https://www.discord.gg/55S32adaKN"> Discord </a></p>
      </div>
      <div className="featur__footer-links_div">
        <h4>Company</h4>
        <p><a href= "https://www.termsfeed.com/live/2447178f-3547-43ea-922c-1a0cab8a9582">Terms & Conditions</a></p>
        <p><a href= "https://www.privacypolicygenerator.info/live.php?token=Y522SiPVM58IpZdjbA7Qfg3bEuDftZ0V"> Privacy Policy</a></p>
      </div>
      <div className="featur__footer-links_div">
        <h4>Contact Info</h4>
        {/* <p>Crechterwoord K12 182 DK Alknjkcb</p> */}
        {/* <p>587-998-0128</p> */}
        <p>info@featur.ca</p>
      </div>
    </div>

    <div className="featur__footer-copyright">
      <p>©Featur owned and operated by Featur Marketing Inc.</p>
    </div>
  </div>
);

export default Footer;