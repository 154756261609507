import React, { Component } from 'react';
import './pdf.css';
import PDFViewer from './components/PDFViewer/PDFViewer';
import WebviewerBackend from './backends/webviewer';

class Pdf extends Component {
  render() {
    return (
      <div className="pdf">
        <PDFViewer backend={WebviewerBackend} src='/OtiumRacing.pdf' />
      </div>
    );
  }
}

export default Pdf;