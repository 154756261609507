import React from 'react';
// import people from '../../assets/people.png';
import ai from '../../assets/ai.svg';
import './header.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Header = () => (
  <div className="featur__header section__padding" id="home">
    <div className="featur__header-content">
      <h1 className="gradient__text">Welcome to Featur</h1>
      <p>Bridging the gap between the real world and the Metaverse</p>

      <div className="featur__header-content__input">
        <a href= "https://www.discord.gg/55S32adaKN">
          <button type="button">
            Otium Discord 
          </button>
        </a>
      </div>

      {/* <div className="featur__header-content__people">
        <img src={people} /> 
        <p>Currently at 1,600 and counting</p>
      </div> */}

      <div className='icons'>
        <div className="featur__icons">
          <InstagramIcon className='iconLogo'/>
          <h3 className="text"><a href= "https://www.instagram.com/featur_dev/"> Instagram </a></h3>
        </div>
        <div className="featur__icons">
          <LinkedInIcon className='iconLogo' />
          <h3 className="text"><a href= "https://www.linkedin.com/company/featur-dev/"> LinkedIn </a></h3>
        </div>
      </div>

    </div>
    <div className="featur__header-image">
      <img src={ai} alt="ai" />
    </div>
  </div>
);

export default Header;